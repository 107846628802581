//-----------------------\\
//--- Carousel Slicks ---\\
//-----------------------\\
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Toutes les fonctions utilisant les carousel de slick se retrouve ici. Ces fonction ont besoin du
// node-module slick carousel pour fonctionner (et le css qui vient avec).
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

export function dropCycle() {
  $('#dropCycle > div').slick({
    zindex: 1,
    arrows: true,
    dots: false,
    appendArrows: '#dropCycle > div',
    nextArrow: '<a href="#" class="slick-next arrowBtn"><span class="icons-box"><span class="icon-right"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span><span class="icon-left"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span></span></a>',
    easing: 'easeInOutExpo',
    infinite: true,
    autoplay: false,
    draggable: true,
    pauseOnHover: false,
    fade: false,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    speed: 1000,
    responsive: [{
      breakpoint: 1600,
      settings: {
        slidesToShow: 4
      }
    }, ]
  })
}

export function productsCycle() {
  $('#productsCycle > div').slick({
    zindex: 1,
    arrows: true,
    dots: false,
    appendArrows: '#productsCycle > div',
    nextArrow: '<a href="#" class="slick-next arrowBtn"><span class="icons-box"><span class="icon-right"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span><span class="icon-left"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span></span></a>',
    easing: 'easeInOutExpo',
    infinite: true,
    autoplay: false,
    draggable: true,
    pauseOnHover: false,
    fade: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    speed: 1000,
    responsive: [{
        breakpoint: 1600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  })
}

export function homeBannerCycle() {
  $('#homeBannerPicCycle').slick({
    zindex: 1,
    arrows: false,
    dots: false,
    easing: 'easeInOutExpo',
    infinite: true,
    autoplay: true,
    draggable: true,
    pauseOnHover: false,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    speed: 1000,
    rtl: true
  })

  $('#homeBannerPicCycle').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
    $(this).find('.slick-cloned').addClass('slick-current-clone')
  })
  $('#homeBannerPicCycle').on('afterChange', function (event, slick, currentSlide) {
    $(this).find('.slick-cloned').removeClass('slick-current-clone')
  })

  $('#homeBannerDescCycle').slick({
    zindex: 1,
    arrows: false,
    dots: true,
    easing: 'easeInOutExpo',
    infinite: true,
    autoplay: true,
    draggable: true,
    pauseOnHover: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 5000,
    speed: 500,
    asNavFor: '#homeBannerPicCycle',
    responsive: [{
      breakpoint: 1200,
      settings: {
        adaptiveHeight: true
      }
    }, ]
  })
}

export function teamCycle() {
  $('#teamCycle').slick({
    zindex: 1,
    arrows: true,
    dots: false,
    appendArrows: '#teamCycle',
    nextArrow: '<a href="#" class="slick-next arrowBtn"><span class="icons-box"><span class="icon-right"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span><span class="icon-left"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span></span></a>',
    prevArrow: '<a href="#" class="slick-prev arrowBtn"><span class="icons-box"><span class="icon-right"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span><span class="icon-left"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span></span></a>',
    easing: 'easeInOutExpo',
    infinite: true,
    autoplay: false,
    draggable: true,
    pauseOnHover: false,
    fade: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    speed: 1000,
    responsive: [{
        breakpoint: 1400,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 501,
        settings: {
          slidesToShow: 1
        }
      },
    ]
  })
}

export function aproposCycle() {
  $('#aproposCycle').slick({
    zindex: 1,
    arrows: true,
    dots: false,
    appendArrows: '#aproposCycle',
    nextArrow: '<a href="#" class="slick-next arrowBtn"><span class="icons-box"><span class="icon-right"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span><span class="icon-left"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span></span></a>',
    prevArrow: '<a href="#" class="slick-prev arrowBtn"><span class="icons-box"><span class="icon-right"><svg class="icon icon-stroke" role="img"><title>Précécent</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span><span class="icon-left"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span></span></a>',
    easing: 'easeInOutExpo',
    infinite: true,
    autoplay: false,
    draggable: true,
    pauseOnHover: false,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    speed: 1000
  })
}

export function porteCycle() {
  $('#porteCycle').slick({
    zindex: 1,
    arrows: true,
    dots: false,
    appendArrows: '#porteCycle',
    nextArrow: '<a href="#" class="slick-next arrowBtn"><span class="icons-box"><span class="icon-right"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span><span class="icon-left"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span></span></a>',
    prevArrow: '<a href="#" class="slick-prev arrowBtn"><span class="icons-box"><span class="icon-right"><svg class="icon icon-stroke" role="img"><title>Précécent</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span><span class="icon-left"><svg class="icon icon-stroke" role="img"><title>Suivant</title><use xlink:href="/themes/mlx/assets/images/icones/icons.svg#ico-arrow"></use></svg></span></span></a>',
    easing: 'easeInOutExpo',
    infinite: true,
    autoplay: false,
    draggable: true,
    pauseOnHover: false,
    fade: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplaySpeed: 3000,
    speed: 1000
  })
}

// Fonction permettant de détruire un carousel Slick
export function destroySlick(carousel, delai) {
  // Paramètres optionnels
  delai = (typeof delai === 'undefined') ? 1000 : delai

  // Un délai pour que le slick ne se détruise pas avant que la transition de page ne soit apparu
  setTimeout(function () {
    $(carousel).slick('unslick')
  }, delai)
}
