//-----------------------------\\
//--- Transition avec barba ---\\
//-----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// C'est ici que les transition entre les page sont gérées, on y retrouve les différentes views spécifiques à
// chaque page. Tout commence avec la fonction doOnce.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'
import $ from 'jquery'
import { setView } from './views.js'
import { Loader } from './classes/loader.js'
//-----------------------------------------------------------------------------------------------------------------

// Cette fonction Gère la transition entre les page
export function initPageTransition() {
  Barba.Pjax.cacheEnabled = false
  Barba.Pjax.Dom.wrapperId = 'transition-wrapper'
  Barba.Pjax.Dom.containerClass = 'transition-container'
  Barba.Pjax.ignoreClassLink = 'no-transition'
  Barba.Dispatcher.on('initStateChange', function(currentStatus) {
    if( typeof gtag === 'function' ) gtag('config', 'UA-38173338-2', { 'page_path': location.pathname }) // Google tag
  })
  setTransition()
  setView()
  Barba.Pjax.start()
}


// Cette fonctions exécute la transition entre les pages
function setTransition() {
  var preloader = new Loader()
  var FadeTransition = Barba.BaseTransition.extend({
    start: function () {
      Promise
        .all([this.newContainerLoading, this.fadeOut()])
        .then(this.fadeIn.bind(this))
    },
    fadeOut: function () {
      preloader.reset()
      setTimeout(function () {
        $('html').removeClass('show-sideMenu')
      }, 1000)
      return $('html').addClass('transition').delay(1020).promise()
    },
    fadeIn: function () {
      preloader.start()
      if (!window.location.hash) $('html, body').animate({ scrollTop: $('html').offset().top }, 200)
      preloader.setCompleteCallback([() => {
        $('html').addClass('closeTransition')
        setTimeout(function () {
          $('html').removeClass('closeTransition transition')
        }, 1000)
        this.done()
      }])
    }
  })

  Barba.Pjax.getTransition = function () {
    return FadeTransition
  }
}
