//--------------------------------\\
//--- Validation de formulaire ---\\
//--------------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve toutes les validations de formulaire, les messages d'erreur et le formatage.
// Pour que tout fonctionne, il faut les node-modules formatter et jquery-validation déjà.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------


// LES FONCTIONS
export function infolettreForm(form, submit) {

  var m = getMessages()
  var rules = 	{ courriel: { required: true, email: true }}
  var messages = 	{ courriel: { required: m.infolettre, email: m.email }}
  formValidation(form, submit, rules, messages)
}

export function contactForm(form, submit) {
  formatPhone('#telephone')
  var m = getMessages()
  var rules = 	{ 
    nom: 		{ required: true },
    prenom: 	{ required: true },
    courriel: 	{ required: true, email: true },
    telephone: 	{ required: true, minlength: 14 },
    message: 	{ required: true }
  }

  var messages = 	{ 
    nom: 		{ required: m.required },
    prenom: 	{ required: m.required },
    courriel: 	{ required: m.required, email: m.email },
    telephone: 	{ required: m.required, minlength: m.phone },
    message: 	{ required: m.message }
  }
	
  formValidation(form, submit, rules, messages)
}

export function partnerForm(form, submit) {
  formatPhone('#telephone')
  var m = getMessages()
  var rules = { 
    nom: 		{ required: true },
    cie: 	{ required: true },
    courriel: 	{ required: true, email: true },
    telephone: 	{ minlength: 14 }
  }

  var messages = 	{ 
    nom: 		{ required: m.required },
    cie: 	{ required: m.required },
    courriel: 	{ required: m.required, email: m.email },
    telephone: 	{ required: m.required, minlength: m.phone }
  }
	
  formValidation(form, submit, rules, messages)
}

export function emploiForm(form, submit) {

  formatPhone('#telephone')
  var m = getMessages()
  var formEmploiValidator
  var value

  $( form + ' input[type=file]').change(function() {

    value = $(this).val().split(/(\\|\/)/g).pop()
    if ( value )
    {
      $(this).addClass('fileAdded')
      $(this).next('label').find('span.withFile').text(value)
    }
    else
    {
      $(this).removeClass('fileAdded')
      $(this).next('label').find('span.withFile').text('')
    }

    formEmploiValidator.element('#' + $(this).attr('id'))
  })

  $.validator.addMethod('filesize', function (value, element, param) {
    return this.optional(element) || (element.files[0].size <= param)
  }, 'La taille maximale du fichier doit être 5MB')

  var rules = 	{
    prenom: 	{ required: true },
    nom: 		{ required: true },
    courriel: 	{ required: true, email: true },
    telephone: 	{ required: true, minlength: 14 },
    cv: 		{ accept: 'pdf', filesize: 5242880, required: true },
  }

  var messages = 	{
    prenom: 	{ required: m.required },
    nom: 		{ required: m.required },
    courriel: 	{ required: m.required, email: m.email },
    telephone: 	{ required: m.required, minlength: m.phone },
    cv: 		{ accept: m.accept, required: m.required }
  }

  formValidation(form, submit, rules, messages)
}


export function loginForm(form, submit) {
  var m = getMessages()
  var rules = 	{ 
    login: 		{ required: true },
    password: 	{ required: true },
  }

  var messages = 	{ 
    login: 		{ required: m.required },
    password: 	{ required: m.required },
  }
	
  formValidation(form, submit, rules, messages)
}

export function resetPswdForm(form, submit) {
  var m = getMessages()
  var rules = 	{ 
    email: 		{ required: true },
  }

  var messages = 	{ 
    email: 		{ required: m.required },
  }
	
  formValidation(form, submit, rules, messages)
}

export function resetPswdForm2() {
  var m = getMessages()
  var rules = 	{ 
    code: 		{ required: true },
    password: 		{ required: true },
  }

  var messages = 	{ 
    code: 		{ required: m.required },
    password: 		{ required: m.required },
  }
	
  formValidation('#resetPswd2', '#resetPswdBtn2', rules, messages)
}

export function searchForm() {
  var m = getMessages()
  var rules = 	{ 
    q: 		{ required: true },
  }

  var messages = 	{ 
    q: 		{ required: m.required },
  }

  if($('#mainSearchForm input').val()){
    $('#mainSearchForm input').addClass('valid')
  }
	
  formValidation('#mainSearchForm', '#mainSubmitSearch', rules, messages)
}


// LES MESSAGES
// Fonction contenant tous les messages
export function getMessages() {
  return {
    required: 'Ce champ est obligatoire.',
    infolettre: 'Veuillez fournir votre adresse électronique.',
    select: 'Veuillez sélectionner une option.',
    email: 'Veuillez fournir une adresse électronique valide.',
    phone: 'Veuillez fournir un numéro de téléphone valide.',
    postale: 'Veuillez fournir un code postal valide.',
    date: 'Veuillez fournir une date valide.',
    accept: 'Le fichier doit être un document pdf, docx, doc ou rtf.',
    file: 'Veuillez lier le fichier adéquat pour ce champ.',
    message: 'Veuillez écrire votre demande ou commentaire.'
  }
}

// LES VALIDATIONS __________
// Fonction exécutant la validation de chaque formulaire
export function formValidation(form, submit, rules, messages) {
  $.validator.setDefaults({ ignore: [] })
  $(form).validate({
    debug: false,
    rules: rules,
    messages: messages,
    errorElement: 'div',
    errorPlacement: function(error, element) {
      var placement = $(element).data('error')
      if (placement) {
        $(placement).append(error)
      } else {
        error.insertBefore(element)
      }
    }
  })
  $(submit).click(function() {
    $(form).submit()
    return false
  })
}

// LES FORMATS __________
// Format du téléphone
export function formatPhone(element) {
  $(element).mask('(000) 000-0000')
}