//-------------------------\\
//--- Classes des views ---\\
//-------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier, on retrouve les différentes fonctions utilisées par les views dans transition.js. Ces fonction
// vont servir à appeler les scripts spécifiques ou les plugins utilisés dans les page web. Les fonctions appelées
// sont réparties dans différents fichiers (slicks, overlay), afin de garder le tout le plus structuré possible.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { SCROLLFIRE } from './main.js'
import { noSamePageDestination, removeHoverOnMobile, onResize, scrollToBlock, initMainNavBar, initMainNavDrop, initHomeBannerHeight, initContactBannerHeight, ocList, initSelect, initNewsFilter, lexiqueFilterClick, manageRubriqueHash, addSlugToShare } from './functions/functions.js'
import { shareModal, initSideMenu } from './functions/overlay.js'
import { dropCycle, productsCycle, homeBannerCycle, teamCycle, aproposCycle, porteCycle, destroySlick } from './functions/slicks.js'
import { infolettreForm, emploiForm, contactForm, loginForm, resetPswdForm, searchForm, partnerForm } from './functions/forms.js'
//-----------------------------------------------------------------------------------------------------------------

// Initialisation sur toutes les pages
export class View {
  static init() {
    noSamePageDestination()
    removeHoverOnMobile()
    //$.fn.BLK_scrollfire()
    SCROLLFIRE.init({ customScroll: true, mobile: true })
    onResize()
    initMainNavBar()
    initMainNavDrop()
    teamCycle()
    dropCycle()
    initSideMenu()
    shareModal()
    infolettreForm('#infolettreForm', '#infolettreFormSubmit')
    scrollToBlock ('#scrollBack', '#transition-wrapper')
    addSlugToShare()
  }

  static leave() {}
}

export class Home extends View {
  static initHome() {
    this.init()
    initHomeBannerHeight()
    homeBannerCycle()
    productsCycle()
    scrollToBlock ('#scrollDown', '#gamme')
  }

  static leaveHome() {
    this.leave()
  }
}

export class Generique extends View {
  static initGenerique() {
    this.init()
    scrollToBlock ('#bannerScroll', '#aproposCycle')
    aproposCycle()
    ocList()
    porteCycle()
    manageRubriqueHash()
  }

  static leaveGenerique() {
    this.leave()
  }
}

export class Apropos extends View {
  static initApropos() {
    this.init()
    scrollToBlock ('#bannerScroll', '#aproposCycle')
    aproposCycle()
    ocList()
  }

  static leaveApropos() {
    this.leave()
  }
}

export class Devenirmembre extends View {
  static initDevenirmembre() {
    this.init()
    partnerForm("#partnerForm", "partnerFormSubmit")
  }

  static leaveDevenirmembre() {
    this.leave()
  }
}

export class News extends View {
  static initNews() {
    this.init()
    initSelect()
    initNewsFilter()
  }

  static leaveNews() {
    this.leave()
  }
}

export class Carrieres extends View {
  static initCarrieres() {
    this.init()
    emploiForm('#emploiForm', '#emploiFormSubmit')
  }

  static leaveCarrieres() {
    this.leave()
  }
}

export class Partenaires extends View {
  static initPartenaires() {
    this.init()
    initSelect()
    ocList()
  }

  static leavePartenaires() {
    this.leave()
  }
}

export class Login extends View {
  static initLogin() {
    this.init()
    loginForm('form[data-request="onSignin"]', '#loginFormSubmit')
  }

  static leaveLogin() {
    this.leave()
  }
}

export class PswdReset extends View {
  static initPswdReset() {
    this.init()
    resetPswdForm('#resetPswd', '#loginFormSubmit')
  }

  static leavePswdReset() {
    this.leave()
  }
}

export class Prive extends View {
  static initPrive() {
    this.init()
    initSelect()
    ocList()
    lexiqueFilterClick()
    searchForm()
  }

  static leavePrive() {
    this.leave()
  }
}

export class Contact extends View {
  static initContact() {
    this.init()
    initContactBannerHeight()
    scrollToBlock ('#scrollToForm', '#formRow')
    contactForm('#contactForm', '#contactFormSubmit')
  }

  static leaveContact() {
    this.leave()
  }
}
