//---------------------------\\
//--- Fonctions générales ---\\
//---------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Dans ce fichier on retrouve les fonctions générales qu'on ne peut pas vraiment catégoriser
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
//-----------------------------------------------------------------------------------------------------------------

// Empêche une transition si la destination est la page actuelle
export function noSamePageDestination() {
  var links = document.querySelectorAll('a[href]')
  var cbk = function(e) {
    if (e.currentTarget.href === window.location.href) {
      e.preventDefault()
      e.stopPropagation()
    }
  }
  for (var i = 0; i < links.length; i++) {
    links[i].addEventListener('click', cbk)
  }
}


// Retire tous les effets d'over sur le mobile
export function removeHoverOnMobile() {
  if ('ontouchstart' in document.documentElement || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
    try { // prevent exception on browsers not supporting DOM styleSheets properly
      for (var si in document.styleSheets) {
        var styleSheet = document.styleSheets[si]
        if (!styleSheet.rules) continue

        for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
          if (!styleSheet.rules[ri].selectorText) continue
          if (styleSheet.rules[ri].selectorText.match(':hover')) styleSheet.deleteRule(ri)
        }
      }
    } catch (ex) {}
  }
}

// Fonction permettant de défiller d'un block à un autre à l'aide d'un clique
export function scrollToBlock (depart, destination, duration, ajustement) {
  // Paramètres optionnels
  duration   = (typeof duration   === 'undefined') ? 600 : duration
  ajustement = (typeof ajustement === 'undefined') ? 0   : ajustement

  // Défilement au clique
  $(depart).on( 'click', function() {
    if($(window).width() <= 1024) ajustement = 0
    //$.fn.BLK_smoothScroll.destroy()
    $('html, body').animate({
      scrollTop: ( destination != '' ? $(destination).offset().top + ajustement : 0 )
    }, duration, 'easeInOutExpo', function() {
      //$.fn.BLK_smoothScroll()
    })
  })
  return false
}

// Gestion navBar du Menu
export function initMainNavBar() {
  var navbartimeout, linkspace
  $('#mainMenu ul').hoverIntent(showNavBar,hideNavBar,'li')
  function showNavBar() {
    clearTimeout(navbartimeout)
    linkspace = $('span', this)
    if ( $('#navBar').width() == 0 ){
      $('#navBar').css('left', (linkspace.position().left + linkspace.width()/2) + 'px')
      $('#navBar').stop(true, false).animate({width: linkspace.width() + 'px', left: linkspace.position().left +  'px'}, 500, 'easeOutCirc')
    } else $('#navBar').stop(true, false).animate({width: linkspace.width() + 'px', left: linkspace.position().left + 'px'}, 500, 'easeOutCirc')
  }
  function hideNavBar(){}

  $('#mainMenu ul').hoverIntent(showNavBar2,hideNavBar2)
  function showNavBar2() {}
  function hideNavBar2() {
    navbartimeout = setTimeout( function() {
      $('#navBar').stop(true, false).animate({width: '0px', left: (linkspace.position().left + linkspace.width()/2) + 'px'}, 200, 'easeOutCirc')
    }, 300)
  }
}

// Gestion open/close du dropdown
export function initMainNavDrop() {
  $('#mainNavDropLink').hoverIntent( showMainDrop, hideMainDrop)
  function showMainDrop() { $('html').addClass('show-mainNavDrop') }
  function hideMainDrop() { $('html').removeClass('show-mainNavDrop') }
}

//Resizing
export function onResize() {
  var width = $(window).width()

  $(window).on( 'resize', function() {
    var newWidth = $(window).width()

    if ( newWidth == width ) {
      return
    } else {
      width = $(window).width()
      $('html').removeClass('show-sideMenu')
    }
  })
}

// Bannière accueil
export function initHomeBannerHeight() {	
  var windowHght
  var topNavHght = $('#topNav').height()
  var mainNavHght = $('#mainNav').height()

  if ( $('#topNav').is(':visible') ) windowHght = $(window).height() - topNavHght
  else windowHght = $(window).height() - mainNavHght
	
  $('#homeBanner').css({'height': windowHght + 'px', 'line-height': windowHght + 'px'})
	
  $(window).resize( function() {
    topNavHght = $('#topNav').height()
    mainNavHght = $('#mainNav').height()	
    if ( $('#topNav').is(':visible') ) windowHght = $(window).height() - topNavHght
    else windowHght = $(window).height() - mainNavHght
    $('#homeBanner').css({'height': windowHght + 'px', 'line-height': windowHght + 'px'})
  })
}


// Permet de gérer un tiroir avec un défilement
function hashDrawer(element) {
  $('.ocList li.open .ocListCt').animate({ height: 'hide' }, 400)
  $('.ocList li.open').removeClass('open')
  if (!$(element).hasClass('open')) {
    setTimeout(function () { $(element).addClass('open') }, 0)
    $('.ocListCt', $(element)).stop(true, false).animate({ height: 'show' }, 400, function () { hashScrolling(element) })
    //Enleve l'ancre de l'url une fois que la rubrique est ouverte
    history.replaceState(null, null, ' ')
  }
  return false
}

// Permet de défiler à une ancre
function hashScrolling(destination) {
  // let offset = document.querySelector('.sRubric > div').dataset.scrollfire != 'fire' ? -100 : 0
  $('html, body').animate({ scrollTop: (destination != '' ? $(destination).offset().top : 0) }, 600, 'easeInOutExpo')
}

export function manageRubriqueHash() {
  //Pour ouvrir une rubrique dans la même page
  window.onhashchange = function () {
    var id = location.hash.split('#').pop()
    var hash = '#rubrique' + id
    if ($('li' + hash).length) {
      hashDrawer(hash)
      $('html, body').animate({ scrollTop: (hash != '' ? $(hash).offset().top-10 : 0) }, 600, 'easeInOutExpo')
    }
  }

  //Pour ouvrir une rubrique dans une autre page
  $('document').ready(function () {
    if (location.hash.length) {
      var id = location.hash.split('#').pop()
      var hash = '#rubrique' + id
      if ($('li' + hash).length) {
        hashDrawer(hash)
        $('html, body').animate({ scrollTop: (hash != '' ? $(hash).offset().top-10 : 0) }, 600, 'easeInOutExpo')
      }
    }
  })
}

// Bannière page contact
export function initContactBannerHeight() {	
  var windowHght
  var topNavHght = $('#topNav').height()
  var mainNavHght = $('#mainNav').height()
	
  if ( $('#topNav').is(':visible') ) windowHght = $(window).height() - topNavHght
  else windowHght = $(window).height() - mainNavHght
	
  $('#coord').css({'height': windowHght + 'px', 'line-height': windowHght + 90 + 'px'})
	
  $(window).resize( function() {
    topNavHght = $('#topNav').height()
    mainNavHght = $('#mainNav').height()	
    if ( $('#topNav').is(':visible') ) windowHght = $(window).height() - topNavHght
    else windowHght = $(window).height() - mainNavHght
    $('#coord').css({'height': windowHght + 'px', 'line-height': windowHght + 90 + 'px'})
  })
}

// Open/Close List
export function ocList() {
  $('.ocList > li > a').unbind( 'click' ) // Pour éviter de binder le click 2 fois
  $('.ocList > li > a').on('click', function() {
    var ouvert = $(this).parent().hasClass('open')
    $('.ocList li.open .ocListCt').animate({ height: 'hide' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})
    $('.ocList li.open').removeClass('open')
    if (!ouvert) {
      var chosen = this
      setTimeout(function() { $(chosen).parent().addClass('open') }, 0)
      $('.ocListCt', $(this).parent()).stop(true, false).animate({ height: 'show' }, { duration: 400, specialEasing: { height: 'easeOutExpo' }})

      console.log(chosen)
      setTimeout(function() {
        $('html, body').animate({
          scrollTop: $(chosen).offset().top - 60
        }, 300);
      }, 400)
    }
    return false
  })
}

// Select
export function initSelect() {
  $('.chosen-select').chosen({ disable_search: true })
  $('.chosen-results').addClass('innerscroll')
}

export function newsLoaded(data) {
  if (!data['hasMorePages']) $('#newsMoreBtn a').hide()
  var pageSelector = $('.eventsPage' + data['currentPage'])
  console.log('.eventsPage' + data['currentPage'])
  console.log(pageSelector)
  //setTimeout( function() { $('html, body').animate({ scrollTop: pageSelector.offset().top - 100}, 600, 'easeInOutExpo', function() {});}, 250)
}

export function partnersLoaded(data) {
  if (!data['hasMorePages']) $('#partnersMoreBtn a').hide()
  var pageSelector = $('.eventsPage' + data['currentPage'])
  console.log('.eventsPage' + data['currentPage'])
  console.log(pageSelector)
  ocList()
  $('.chosen-select').trigger("chosen:updated")
  //setTimeout( function() { $('html, body').animate({ scrollTop: pageSelector.offset().top - 100}, 600, 'easeInOutExpo', function() {});}, 250)
}

export function portfolioLoaded(data) {
  if (!data['hasMorePages']) $('#portfolioMoreBtn').hide()
  var pageSelector = $('.portfolioPage' + data['currentPage'])
  console.log('.eventsPage' + data['currentPage'])
  console.log(pageSelector)
  ocList()
  //setTimeout( function() { $('html, body').animate({ scrollTop: pageSelector.offset().top - 100}, 600, 'easeInOutExpo', function() {});}, 250)
}

export function lexiqueLoaded(data) {
  console.log(data)
  if (!data['hasMorePages']) $('#lexiqueMoreBtn').hide()
  ocList()
}

export function initNewsFilter() {
  $('#desktopFilter ul li').on('click touchstart', function() {
    $('#desktopFilter ul li').removeClass('active')
    $(this).addClass('active')
  })
}

export function lexiqueFilterClick(){
  $('.lexiqueFilter .filter_item').on('click touchstart', function() {
    $('.lexiqueFilter .filter_item').removeClass('active')
    $(this).addClass('active')
  })
}

export function copyTextToClipboard(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text)
    return
  }
  navigator.clipboard.writeText(text).then(function() {
    console.log('Async: Copying to clipboard was successful!')
  }, function(err) {
    console.error('Async: Could not copy text: ', err)
  })
}

export function fallbackCopyTextToClipboard(text) {

  var textArea = document.createElement('textarea')
  textArea.value = text
  document.body.appendChild(textArea)
  textArea.focus()
  textArea.select()

  try { var successful = document.execCommand('copy') } catch (err) { successful = false }

  document.body.removeChild(textArea)
  $(document).scrollTop(0)
}

export function addSlugToShare() {
  $('.shareBtn.rubric').on('click', function(e) {
    window.history.pushState(null, null, '#' + e.currentTarget.id)
    //pour le partage par courriel
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Mélymax&body=' + window.location.href)
    //pour le partage par facebook ou twitter
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
    $('#linkedinShareBtn').attr('data-url', window.location.href)
  })

  //clean l'url quand on ferme l'overlay de share
  $('#overlay, .closeOverlay, #shareCloseBtn').on('click', function() {
    window.history.replaceState(null, null, ' ')
    //remettre le partage de la page (sans #)
    $('#emailShareBtn').attr('href', 'mailto:?Subject=Mélymax&body=' + window.location.href)
    $('#facebookShareBtn').attr('data-url', window.location.href)
    $('#twitterShareBtn').attr('data-url', window.location.href)
    $('#linkedinShareBtn').attr('data-url', window.location.href)
  })
}