//----------------------------\\
//---- Popup et sidepanel ----\\
//----------------------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ici on retrouve tout ce qui a attrait au popup et au sidepanel (popup de connexion, menu mobile)
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import $ from 'jquery'
import { Overlay } from './../classes/overlay.js'
import { copyTextToClipboard } from './functions.js'
//-----------------------------------------------------------------------------------------------------------------

export function initSideMenu() {

  $('.hamburger').click( function() {
    if ( !$('html').hasClass('show-sideMenu')) {
      $('#sideMenu .sidePanelContainer').scrollTop(0)  
      $('html').addClass('show-sideMenu')
      $('.hamburger').addClass('is-active')
    } else {
      $('html').removeClass('show-sideMenu')
      $('.hamburger').removeClass('is-active')
      initCloseSubMenu()
    }
    return false
  })

  $('#overlay').click( function() {
    $('html').removeClass('show-sideMenu')
    $('.hamburger').removeClass('is-active')
    initCloseSubMenu()
    return false
  })

  $('#sideMenu .sub > a').click( function() {
    var clickedli = $(this).parent()
    var clickedul = $(this).parent().parent()
    var toggling = clickedli.hasClass('open')
    $('li.open > div', clickedul).animate( {height: 'hide'}, {duration: 600, specialEasing: { height: 'easeOutExpo' }})
    $('li.open', clickedul).removeClass('open')
    if ( !toggling ) {
      setTimeout(function() { clickedli.addClass('open') }, 0)
      $('> div', clickedli).stop(true, false).animate( {height: 'show'}, { duration: 500, specialEasing: { height: 'easeOutExpo' }})
    }
    return false
  })
}

function initCloseSubMenu() {
  setTimeout(function() {
    $('#sideMenu li.open > div').hide()
    $('#sideMenu li.open').removeClass('open')
  }, 650)
}

// Fonction gérant le popup de partage
export function shareModal() {

  if (typeof __sharethis__ !== 'undefined' && __sharethis__.config) {
    __sharethis__.init(__sharethis__.config)
  }

  $('#copyShareBtn').click( function() {
    var link = $(this).attr('data-url')
    copyTextToClipboard(link)
    copyTextToClipboard(window.location.href)
    alert('Un url a été copié sur votre presse-papiers.')
    return false
  })

  $('.shareBtn').click( function() {
    $('html').addClass('show-shareModal')
  })

  $('#shareCloseBtn').click( function() {
    $('html').removeClass('show-shareModal')
    return false
  })

  $('.closeOverlay').click( function() {
    $('html').removeClass('show-shareModal')
    return false
  })
}