//-----------------\\
//--- Les views ---\\
//-----------------\\

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// Ce fichier contient une seule fonction qui celle-ci contient toutes les views du site. Une views équivaux à
// une page web (homeView = la page d'accueil). On appelle seulement les classes dans global dans les vues, on
// n'appelle pas d'autres fonctions ou déclarer quoi que ce soit dans les vues afin de garder le tout clair.
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// Importation ----------------------------------------------------------------------------------------------------
import Barba from 'barba.js'
import $ from 'jquery'

import {
  View, Home, Generique, Apropos, Devenirmembre, News, Carrieres, Partenaires, Login, PswdReset, Prive, Contact
} from './global.js'
//-----------------------------------------------------------------------------------------------------------------

export function setView() {

  // Page standart __________
  Barba.BaseView.extend({
    namespace: 'stdView',
    onEnter:          function() {              },
    onEnterCompleted: function() { View.init()  },
    onLeave:          function() { View.leave() },
    onLeaveCompleted: function() {              }
  }).init()

  // Page d'accueil __________
  Barba.BaseView.extend({
    namespace: 'homeView',
    onEnter:          function() {},
    onEnterCompleted: function() { Home.initHome()  },
    onLeave:          function() { Home.leaveHome() },
    onLeaveCompleted: function() {}
  }).init()

  // Page générique __________
  Barba.BaseView.extend({
    namespace: 'generiqueView',
    onEnter:          function() {},
    onEnterCompleted: function() { Generique.initGenerique()  },
    onLeave:          function() { Generique.leaveGenerique() },
    onLeaveCompleted: function() {}
  }).init()

  // Page A propos __________
  Barba.BaseView.extend({
    namespace: 'aproposView',
    onEnter:          function() {},
    onEnterCompleted: function() { Apropos.initApropos()  },
    onLeave:          function() { Apropos.leaveApropos() },
    onLeaveCompleted: function() {}
  }).init()

  // Page Devenir membre __________
  Barba.BaseView.extend({
    namespace: 'devenirmembreView',
    onEnter:          function() {},
    onEnterCompleted: function() { Devenirmembre.initDevenirmembre()  },
    onLeave:          function() { Devenirmembre.leaveDevenirmembre() },
    onLeaveCompleted: function() {}
  }).init()

  // Page News __________
  Barba.BaseView.extend({
    namespace: 'newsView',
    onEnter:          function() {},
    onEnterCompleted: function() { News.initNews()  },
    onLeave:          function() { News.leaveNews() },
    onLeaveCompleted: function() {}
  }).init()

  // Page Carrieres __________
  Barba.BaseView.extend({
    namespace: 'carrieresView',
    onEnter:          function() {},
    onEnterCompleted: function() { Carrieres.initCarrieres()  },
    onLeave:          function() { Carrieres.leaveCarrieres() },
    onLeaveCompleted: function() {}
  }).init()

  // Page Partenaires __________
  Barba.BaseView.extend({
    namespace: 'partenairesView',
    onEnter:          function() {},
    onEnterCompleted: function() { Partenaires.initPartenaires()  },
    onLeave:          function() { Partenaires.leavePartenaires() },
    onLeaveCompleted: function() {}
  }).init()

  // Page Login __________
  Barba.BaseView.extend({
    namespace: 'loginView',
    onEnter:          function() {},
    onEnterCompleted: function() { Login.initLogin()  },
    onLeave:          function() { Login.leaveLogin() },
    onLeaveCompleted: function() {}
  }).init()

  // Page Rappel Mot de passe __________
  Barba.BaseView.extend({
    namespace: 'pswdResetView',
    onEnter:          function() {},
    onEnterCompleted: function() { PswdReset.initPswdReset()  },
    onLeave:          function() { PswdReset.leavePswdReset() },
    onLeaveCompleted: function() {}
  }).init()

  // Page Zone privée __________
  Barba.BaseView.extend({
    namespace: 'priveView',
    onEnter:          function() {},
    onEnterCompleted: function() { Prive.initPrive()  },
    onLeave:          function() { Prive.leavePrive() },
    onLeaveCompleted: function() {}
  }).init()

  // Page Contact __________
  Barba.BaseView.extend({
    namespace: 'contactView',
    onEnter:          function() {},
    onEnterCompleted: function() { Contact.initContact()  },
    onLeave:          function() { Contact.leaveContact() },
    onLeaveCompleted: function() {}
  }).init()
}
